@import "../../_globalColor";

#contact {
  border-left: 5px solid #c8670b;
  border-right: 5px solid #c8670b;
  width: 60%;
  background: beige;
  padding: 50px;
  border-radius: 100px 5px 100px;
}

.profile-image {
  border-radius: 50%;
  border: 5px solid rgba(15, 15, 15, 0.0588235294)!important;
  margin-left: auto;
  width: 100%;
  max-width: 350px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0 30px 30px -30px;
  transition: all 0.3s ease-out;
}

.bio-text {
  font-size: 16px;
  color: chocolate!important
}

.subTitle.blog-subtitle {
  font-weight: bold!important;
}

.prof-title {
  font-size: 35px!important;
  font-weight: 600!important;
  color: sienna
}

.desc-prof {
  font-weight: 600;
  color: darkcyan;
}

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
